import React from "react";
import "../App.css";

const TokenSystem = ({ tokens, onUseTokens, onAwardTokens }) => {
  const awardOptions = [0, 1, 2, 3];

  return (
    <div>
      <h6>Tokens: {tokens}</h6>
      {awardOptions.map((amount) => (
        <button
          key={amount}
          onClick={() => onAwardTokens(amount)}
        >{`Award ${amount} Token${amount !== 1 ? "s" : ""}`}</button>
      ))}
    </div>
  );
};

export default TokenSystem;
