import React, { useState } from 'react';
import "../App.css";

const AddActivityForm = ({ onAddActivity }) => {
  const [activityName, setActivityName] = useState('');
  const [tokenCost, setTokenCost] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activityName && tokenCost) {
      onAddActivity({ name: activityName, tokenCost: parseInt(tokenCost, 10) });
      setActivityName('');
      setTokenCost('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={activityName}
        onChange={(e) => setActivityName(e.target.value)}
        placeholder="Activity Name"
        required
      />
      <input
        type="number"
        value={tokenCost}
        onChange={(e) => setTokenCost(e.target.value)}
        placeholder="Token Cost"
        required
      />
      <button type="submit">Add Activity</button>
    </form>
  );
};

export default AddActivityForm;