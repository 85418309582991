import React from "react";
import "../card.css";

const Card = ({ card, onFlip }) => {
  return (
    <div className={`card`} onClick={() => onFlip(card.id, !card.flipped)}>
      <p>{card.text}</p>
    </div>
  );
};

export default Card;