import React, { useState, useEffect } from "react";
import { initializeGame, joinGame, listenToGame } from "./firebase"; // Adjust the path if needed
import Dice from "./components/Dice";
import Card from "./components/Card";
import TokenSystem from "./components/TokenSystem";
import Coin from "./components/Coin";
import AddActivityForm from "./components/AddActivityForm";
import coinFavor from "./images/coin_favor.png";
import coinChallenge from "./images/coin_challenge.png";
import gameComponentsImage from "./images/game_components.png";
import { categoryFiveCards, cards } from "./cardData";
import { handleDiceRoll as handleDiceRollLogic, handleTokenUse, handleTokenAward, handleCoinToss as handleCoinTossLogic } from "./utils/gameLogic";
import "./App.css";
import "./dice.css";
import "./coin.css";
import "./card.css";

function App() {
  const [category, setCategory] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);
  const [playerTurn, setPlayerTurn] = useState(0);
  const [playerTokens, setPlayerTokens] = useState([10, 10]);
  const [showCoin, setShowCoin] = useState(false);
  const [playerInventories, setPlayerInventories] = useState([[], []]);
  const [isPaused, setIsPaused] = useState(false);
  const [showTokenAwardMessage, setShowTokenAwardMessage] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [coinTossResult, setCoinTossResult] = useState(null);
  const [isCoinFlipping, setIsCoinFlipping] = useState(false);
  const [activeBuyerIndex, setActiveBuyerIndex] = useState(null);
  const [error, setError] = useState(null);
  const [doOverCount, setDoOverCount] = useState(0);
  const [showCategoryFiveMessage, setShowCategoryFiveMessage] = useState(false);
  const [lastQuestionPoints, setLastQuestionPoints] = useState(0);
  const [userDefinedActivities, setUserDefinedActivities] = useState([]);
  const [defaultActivities, setDefaultActivities] = useState([
    { name: "Hand Massage", tokenCost: 10 },
    { name: "Foot Massage", tokenCost: 20 },
    { name: "Face Massage", tokenCost: 25 },
    { name: "Scalp Massage", tokenCost: 25 },
    { name: "Eye Gazing", tokenCost: 10 },
    { name: "Drawing on back", tokenCost: 15 },
    { name: "Butterfly/Eskimo Kisses", tokenCost: 10 },
    { name: "Feed candy/fruit", tokenCost: 20 },
    { name: "Blindfold for 2 minutes", tokenCost: 40 },
    { name: "Handcuff for 2 minutes", tokenCost: 50 },
    { name: "Strip rock/paper/scissors", tokenCost: 75 },
    { name: "Spanking", tokenCost: 100 },
    { name: "Spicy Selfie", tokenCost: 125 },
    { name: "Demonstrate favorite sex position", tokenCost: 150 },
    { name: "Naked Gymnastics Move", tokenCost: 250 },
  ]);
  const [gameStarted, setGameStarted] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showGameComponents, setShowGameComponents] = useState(true); 

  const [gameId, setGameId] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [playerId, setPlayerId] = useState('');

  const activities = [...defaultActivities, ...userDefinedActivities];

  const handleAddActivity = (activity) => {
    setUserDefinedActivities((prevActivities) => [...prevActivities, activity]);
  };

  const handleDeleteActivity = (activityIndex) => {
    if (activityIndex < defaultActivities.length) {
      setDefaultActivities((prevActivities) => prevActivities.filter((_, index) => index !== activityIndex));
    } else {
      setUserDefinedActivities((prevActivities) => prevActivities.filter((_, index) => index !== (activityIndex - defaultActivities.length)));
    }
  };

  const handleStartGame = () => {
    setGameStarted(true);
    setShowInstructions(false);
  };
  const handleShowGameComponents = () => {
    setShowGameComponents(true);
    setShowInstructions(false);
  };

  const handleShowInstructions = () => {
    setShowInstructions(true);
    setShowGameComponents(false);
  };

  const playerIndex = playerTurn % playerTokens.length;
  const nonRollingPlayerIndex = (playerTurn + 1) % playerTokens.length;

  const handleDiceRoll = (diceValue) => {
    console.log(`Dice rolled: ${diceValue}`);
    handleDiceRollLogic(
      diceValue,
      setCategory,
      setShowTokenAwardMessage,
      setShowCoin,
      setShowCategoryFiveMessage,
      drawCard,
      () => {}, 
      ["Player 1", "Player 2"],
      playerTurn,
      playerIndex
    );
  
    if (diceValue !== 5 && diceValue !== 6) {
      console.log(`Calling drawCard for category ${diceValue}`);
      drawCard(diceValue); 
    }
  
    if (diceValue === 6) {
      setCurrentCard(null); 
      setShowTokenAwardMessage(false); 
      setShowCoin(true); 
    } else if (diceValue === 5) {
      console.log(`Calling drawCard for category 5`);
      drawCard(diceValue);
      setShowTokenAwardMessage(false);
      setShowActivities(false);
      setShowCategoryFiveMessage(true);
      setTimeout(() => {
        setCurrentCard(null); 
        setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length); 
        setShowCategoryFiveMessage(false); 
      }, 2000); 
    } else {
      console.log(`Calling drawCard for category ${diceValue}`);
      drawCard(diceValue); 
      setShowTokenAwardMessage(diceValue >= 1 && diceValue <= 4);
      setShowActivities(false); 
      setShowCategoryFiveMessage(false);
    }
  };

  const handleCoinToss = () => {
    handleCoinTossLogic(
      setCoinTossResult,
      setIsCoinFlipping,
      playerIndex,
      setActiveBuyerIndex,
      nonRollingPlayerIndex,
      setShowActivities
    );
    setShowCoin(false); 
  };

  const drawCard = (category) => {
    let cardsToDrawFrom =
      category === 5
        ? categoryFiveCards
        : cards.filter((card) => card.category === category);
    const randomCard =
      cardsToDrawFrom[Math.floor(Math.random() * cardsToDrawFrom.length)];
    setCurrentCard(randomCard);
  
    console.log(`Drawing card: ${randomCard.text}`);
  
    if (category === 5) {
      setPlayerInventories((prevInventories) => {
        const newInventories = [...prevInventories];
        const playerInventory = newInventories[playerTurn];
        if (!playerInventory.some((card) => card.id === randomCard.id)) {
          newInventories[playerTurn] = [...playerInventory, randomCard];
          console.log(`Added card to inventory: ${randomCard.text}`);
        } else {
          console.log(`Card already in inventory: ${randomCard.text}`);
        }
        return newInventories;
      });
    }
  };

  const applyCardEffect = (playerIndex, cardIndex) => {
    const card = playerInventories[playerIndex][cardIndex];
    const otherPlayerIndex = (playerIndex + 1) % playerTokens.length;
  
    switch (card.effect) {
      case "goOn":
        setPlayerTokens((tokens) => {
          const newTokens = [...tokens];
          newTokens[otherPlayerIndex] += lastQuestionPoints * 2; // Award the other player twice the points for the last question
          return newTokens;
        });
        break;
      case "doOver":
        if (doOverCount < 3) {
          setDoOverCount((count) => count + 1);
          drawCard(currentCard.category);
        } else {
          console.log("Retry limit reached");
        }
        break;
      case "freePass":
        setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length);
        break;
      case "turnItAround":
        // The other player gets to answer the card and receive the points
        setPlayerTurn(otherPlayerIndex);
        setTimeout(() => {
          setCurrentCard(null); // Clear the current card after the other player answers
          setPlayerTurn(playerIndex); // Set the turn back to the original player
          setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length); // Ensure the other player gets their regular turn
        }, 3000); // Adjust delay as necessary for the card effect
        break;
      case "reset":
        setIsPaused(true);
        setTimeout(() => setIsPaused(false), 300000);
        break;
      case "askMeAnything":
        setPlayerTokens((tokens) => {
          const newTokens = [...tokens];
          newTokens[playerIndex] += 10;
          return newTokens;
        });
        break;
      default:
        console.error("Unknown card effect:", card.effect);
    }
  
    // Remove the card from the inventory if it's a category 5 card
    if (card.category === 5) {
      setPlayerInventories((prevInventories) => {
        const newInventories = [...prevInventories];
        newInventories[playerIndex].splice(cardIndex, 1);
        return newInventories;
      });
    }
  };

  const handleActivityPurchase = (playerIndex, activityIndex) => {
    const activity = activities[activityIndex];
    if (playerTokens[playerIndex] >= activity.tokenCost) {
      setPlayerTokens((prevTokens) => {
        const updatedTokens = [...prevTokens];
        updatedTokens[playerIndex] -= activity.tokenCost;
        return updatedTokens;
      });
      console.log(`Player ${playerIndex + 1} purchased ${activity.name}`);
      setShowActivities(false);
      setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length); 
    } else {
      console.log(
        `Player ${playerIndex + 1} does not have enough tokens to purchase ${activity.name}`
      );
    }
  };

  const awardTokens = (amount) => {
    handleTokenAward(amount, playerTurn, playerTokens, setPlayerTokens);
    setLastQuestionPoints(amount); 
    setCurrentCard(null); 
    setShowTokenAwardMessage(false); 
    setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length); 
  };

  const endTurn = () => {
    setShowActivities(false);
    setCoinTossResult(null);
    setShowTokenAwardMessage(false);
    setPlayerTurn((currentTurn) => (currentTurn + 1) % playerTokens.length); 
  };

  useEffect(() => {
    if (gameId) {
      listenToGame(gameId, (game) => {
        setCategory(game.state.currentTurn);
        setCurrentCard(game.state.playedCards);
        setPlayerTokens([
          game.players.player1.score,
          game.players.player2.score
        ]);
        setPlayerInventories([
          game.players.player1.hand,
          game.players.player2.hand
        ]);
        setPlayerTurn(game.state.currentTurn === "player1" ? 0 : 1);
      });
    }
  }, [gameId]);

  const handleCreateGame = () => {
    const newGameId = prompt("Enter a new Game ID");
    if (newGameId) {
      setGameId(newGameId);
      initializeGame(newGameId);
    }
  };

  const handleJoinGame = (e) => {
    e.preventDefault();
    if (gameId && playerName && playerId) {
      joinGame(gameId, playerName, playerId);
      setGameStarted(true);
    } else {
      alert('Please enter a valid Game ID, Player Name, and select a Player ID');
    }
  };

  if (showGameComponents) {
    return (
      <div className="App">
        <h1>Welcome to the IntimaDate Game!</h1>
        <h1>An adult intimacy game for couples!!</h1>
        <img src={gameComponentsImage} alt="Game Components" className="game-components-image" />
        <button onClick={handleShowInstructions}>Continue</button>
      </div>
    );
  }

  if (showInstructions) {
    return (
      <div className="App">
        <h1>Game Instructions</h1>
        <img src={gameComponentsImage} alt="Game Components" className="game-components-image" />
        <h6>Here are the instructions...</h6>
        <h3>WARNING: This game is not for the faint of heart!</h3>
        <h4>In order to play, you must be willing to, both, face things you may prefer to deny and admit things you may find uncomfortable to confess. You must accept your partner in spite of this, and honor their courage to be honest, vulnerable, and open. Emotions may be jostled at times, so great care must be taken to create a space that is safe, compassionate, non-judgmental, exploratory, supportive, and loving.</h4>
        <h5>This game is not about winning and losing, but about being together, learning about each other, practicing vulnerability, patience, acceptance, and appreciation. The game can end either at a predetermined set amount of time or just whenever you both feel like doing something else. At the same time, the game really never ends.</h5>
        <h7>Good luck & ENJOY!</h7>
        <hr />
        <ul>
          <li>Each player will begin with 10 tokens</li>
          <li>Players will take turns rolling the dice</li>
          <li>Based on the dice roll, draw a card or toss the coin</li>
          <li>If you roll 1-4: Draw a card from the corresponding category and engage with the prompt. When finished, the other player will award you tokens based on your effort</li>
          <li>If you roll a 5: Draw a special card and add it to your inventory to use at your discretion.</li>
          <li>If you roll a 6: Flip the coin to determine who may buy an activity</li>
          <h6>After pressing continue, you will go to a new screen where you can either create or join a game. If both players are in the same physical space you can create a game and play on one device. If you are not in the same physical space, then the other player can join the game on a different device as long as they have the Game ID you use to create it.</h6>
          <h5>*Once you create the game, the following screen will display a list of activities for Category 6 (Physical Intimacy) and cost in tokens. These activities are provided as examples only. Prior to starting the game, decide together which physical activities you would like to include and add or remove activities from the list to suit your particular comfort level.</h5>
        </ul>
        <button onClick={() => { setShowInstructions(false); setShowGameComponents(false); }}>Continue</button>
      </div>
    );
  }

      if (!gameId && !gameStarted) {
        return (
          <div>
            <h1>Join a Game</h1>
            <form onSubmit={handleJoinGame}>
              <input
                type="text"
                placeholder="Game ID"
                value={gameId}
                onChange={(e) => setGameId(e.target.value)}
              />
              <input
                type="text"
                placeholder="Player Name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              <select value={playerId} onChange={(e) => setPlayerId(e.target.value)}>
                <option value="">Select Player</option>
                <option value="player1">Player 1</option>
                <option value="player2">Player 2</option>
              </select>
              <button type="submit">Join Game</button>
            </form>
            <button onClick={handleCreateGame}>Create Game</button>
          </div>
        );
      }

      if (!gameStarted) {
        return (
          <div className="App">
            <h1>Setup Activities</h1>
            <AddActivityForm onAddActivity={handleAddActivity} />
            <ul>
              {activities.map((activity, index) => (
                <li key={index}>
                  {activity.name} - {activity.tokenCost} tokens
                  <button onClick={() => handleDeleteActivity(index)}>
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={handleStartGame}>Start Game</button>
          </div>
        );
      }


  return (
    <div className={`App player-${playerTurn}`}>
      {isCoinFlipping && <Coin result={coinTossResult} />}
      {error && <div>Error: {error}</div>}
      {coinTossResult && !isCoinFlipping && (
        <div>
          <img
            src={coinTossResult === "Favor" ? coinFavor : coinChallenge}
            alt={`Coin showing ${coinTossResult}`}
            className="coin-image"
          />
          <p>{`Coin toss result: ${coinTossResult}. Player ${
            activeBuyerIndex + 1
          } may buy an activity if they wish, or end turn to save your tokens.`}</p>
          {showActivities &&
            activities.map((activity, index) => {
              const canAfford = playerTokens[activeBuyerIndex] >= activity.tokenCost;
              return (
                <button
                  key={index}
                  onClick={() => handleActivityPurchase(activeBuyerIndex, index)}
                  disabled={!canAfford}
                  className={`button ${!canAfford ? 'button-disabled' : ''}`}
                >
                  {activity.name} for {activity.tokenCost} tokens
                </button>
              );
            })}
          <button
            onClick={endTurn}
            className="button button-end-turn"
          >
            End Turn
          </button>
        </div>
      )}
  
      {isPaused && <div className="paused">Game is paused for a break</div>}
  
      <h1>{`Player ${playerTurn + 1}'s Turn - Roll the Dice!`}</h1>
      {category && <h2>Category {category} Rolled</h2>}
      <Dice onRoll={handleDiceRoll} />
      {showCoin && <button onClick={handleCoinToss} className="button">Toss the Coin</button>}
      {currentCard && <Card card={currentCard} />} 
      {showTokenAwardMessage && (
        <h2>
          After Player {playerTurn + 1} answers the question, Player{" "}
          {nonRollingPlayerIndex + 1}, award tokens to Player {playerTurn + 1}
        </h2>
      )}
      {category >= 1 && category <= 4 && showTokenAwardMessage && (
        <TokenSystem
          tokens={playerTokens[playerTurn]}
          onUseTokens={(cost) =>
            handleTokenUse(
              playerTokens[playerTurn],
              cost,
              setPlayerTokens,
              "Example Activity"
            )
          }
          onAwardTokens={awardTokens}
        />
      )}
      <div>Player 1 Tokens: {playerTokens[0]}</div>
      <div>Player 2 Tokens: {playerTokens[1]}</div>
  
      <div className="inventory">
        <h6>Player {playerTurn + 1} Inventory:</h6>
        {playerInventories[playerTurn].map((card, index) => (
          <div key={index} className="card-inventory-item">
            <p>{card.text}</p>
            <p>{card.description}</p>
            {card.effect && (
              <button onClick={() => applyCardEffect(playerTurn, index)} className="button">
                Use Card
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;