import React, { useState } from "react";
import diceSide1 from "../images/dice_side1.png";
import diceSide2 from "../images/dice_side2.png";
import diceSide3 from "../images/dice_side3.png";
import diceSide4 from "../images/dice_side4.png";
import diceSide5 from "../images/dice_side5.png";
import diceSide6 from "../images/dice_side6.png";
import '../App';
import "../App.css";
import "../dice.css";

const diceImages = [
  diceSide1,
  diceSide2,
  diceSide3,
  diceSide4,
  diceSide5,
  diceSide6,
];

const Dice = ({ onRoll }) => {
  const [currentSide, setCurrentSide] = useState(diceImages[0]);
  const [result, setResult] = useState(1); 

  const rollDie = () => {
    const rollResult = Math.floor(Math.random() * 6) + 1;
    console.log("Rolling Dice, Result:", rollResult);
    setResult(rollResult); // Update the result state
    setCurrentSide(diceImages[rollResult - 1]); // Update the current side to display
    onRoll(rollResult); // Invoke the passed onRoll function with the actual result (1-6)
  };

  return (
    <div onClick={rollDie}>
      <img
        src={currentSide}
        alt={`Dice side ${result}`}
        style={{ width: "200px", height: "200px" }} />
    </div>
  );
};

export default Dice;