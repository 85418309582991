export const handleDiceRoll = (
  rollResult,
  setCategory,
  setShowTokenAwardMessage,
  setShowCoin,
  setShowCategoryFiveMessage,
  drawCard
) => {
  console.log(`Dice rolled: ${rollResult}`);
  setCategory(rollResult);

  if (rollResult >= 1 && rollResult <= 4) {
    setShowTokenAwardMessage(true);
  } else if (rollResult === 5) {
    setShowCategoryFiveMessage(true);
    // drawCard(rollResult); // Remove this call
  } else if (rollResult === 6) {
    setShowCoin(true);
  }
};

export const handleCoinToss = (
  setCoinTossResult,
  setIsCoinFlipping,
  playerTurn,
  setActiveBuyerIndex,
  nonRollingPlayerIndex,
  setShowActivities // Add setShowActivities parameter
) => {
  setIsCoinFlipping(true); // Start the coin flip animation
  setTimeout(() => {
    const result = Math.random() > 0.5 ? "Favor" : "Challenge";
    setIsCoinFlipping(false);
    setCoinTossResult(result);

    const buyingPlayerIndex = result === "Favor" ? playerTurn : nonRollingPlayerIndex;
    setActiveBuyerIndex(buyingPlayerIndex);
    setShowActivities(true); // Show activities after coin toss
    console.log(`Coin tossed: ${result}. Player ${buyingPlayerIndex + 1} can now buy an activity.`);
  }, 2000); // Simulate coin flip animation
};

export const handleCardDraw = (category, deck, setInventory) => {
  if (deck && deck[category]) {
    const card = deck[category].pop(); // Consider creating a new array if deck is a state
    setInventory((prevInventory) => [...prevInventory, card]);
  }
};

export const handleTokenUse = (tokens, cost, setPlayerTokens, activity) => {
  if (tokens >= cost) {
    setPlayerTokens((prevTokens) => prevTokens - cost);
    console.log(`Activity ${activity} purchased!`);
  } else {
    console.log("Not enough tokens!");
  }
};

export const handleTokenAward = (amount, playerTurn, playerTokens, setPlayerTokens) => {
  const updatedTokens = [...playerTokens];
  updatedTokens[playerTurn] += amount;
  setPlayerTokens(updatedTokens);
};