import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, onValue, update } from "firebase/database";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

function initializeGame(gameId) {
  const gameRef = ref(database, `games/${gameId}`);
  set(gameRef, {
    players: {
      player1: {
        name: "Player 1",
        hand: ["card1", "card2"],
        score: 10
      },
      player2: {
        name: "Player 2",
        hand: ["card3", "card4"],
        score: 10
      }
    },
    state: {
      currentTurn: "player1",
      playedCards: []
    }
  });
}

function joinGame(gameId, playerName, playerId) {
  const playerRef = ref(database, `games/${gameId}/players/${playerId}`);
  update(playerRef, {
    name: playerName,
    hand: [],
    score: 0
  });
}

function listenToGame(gameId, onGameUpdate) {
  const gameRef = ref(database, `games/${gameId}`);
  onValue(gameRef, (snapshot) => {
    const game = snapshot.val();
    if (game) {
      onGameUpdate(game);
    }
  });
}

function playCard(gameId, playerId, card) {
  const gameRef = ref(database, `games/${gameId}`);
  gameRef.transaction((game) => {
    if (game) {
      const playerHand = game.players[playerId].hand;
      const cardIndex = playerHand.indexOf(card);
      if (cardIndex > -1) {
        playerHand.splice(cardIndex, 1);
        game.state.playedCards.push(card);
        game.state.currentTurn = game.state.currentTurn === 'player1' ? 'player2' : 'player1';
      }
    }
    return game;
  });
}

export { database, initializeGame, joinGame, listenToGame, playCard };