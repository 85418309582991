export const cards = [
    {
      id: 1,
      category: 1,
      text: "Explain one thing learned or studied",
      image: "./images/card_back1.png",
      frontImage: "./images/card_front1.png",
    },
    {
      id: 2,
      category: 1,
      text: "Discuss an instance of courage",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 3,
      category: 1,
      text: "Describe your proudest moment",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 4,
      category: 1,
      text: "What attribute of yours is the most notable?",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 5,
      category: 1,
      text: "Describe one accomplishment or achievement",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 6,
      category: 1,
      text: "Speak about your most prominent passion",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 7,
      category: 1,
      text: "Share about a time you felt joyful",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 8,
      category: 1,
      text: "Declare your best habit",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 9,
      category: 1,
      text: "Talk about your greatest strength",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 10,
      category: 1,
      text: "Say what you like most about yourself",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 11,
      category: 1,
      text: "Share your fondest memory",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 12,
      category: 1,
      text: "Introduce one of your heroes",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 13,
      category: 1,
      text: "Share something you have mastered",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 14,
      category: 1,
      text: "Brag about a talent you possess",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 15,
      category: 1,
      text: "Talk about why you are trustworthy",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 16,
      category: 1,
      text: "Reveal one insight or epiphany you've had",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 17,
      category: 1,
      text: "Mention a time you helped someone else",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 18,
      category: 1,
      text: "Announce a contribution you have made to the world",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 19,
      category: 1,
      text: "What is the biggest benefit you bring to a relationship?",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 20,
      category: 1,
      text: "Speak about something you overcame",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 21,
      category: 1,
      text: "Publicize a skill you have developed",
image: "./images/card_back1.png",
frontImage: "./images/card_front1.png",
    },
    {
      id: 22,
      category: 2,
      text: "Confess a genuine insecurity",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 23,
      category: 2,
      text: "Name a thing people criticize about you",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 24,
      category: 2,
      text: "State an instance of shame",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 25,
      category: 2,
      text: "Convey something about which you are self-conscious",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 26,
      category: 2,
      text: "Talk about a time you were hurt",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 27,
      category: 2,
      text: "Communicate a traumatic memory",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 28,
      category: 2,
      text: "Acknowledge a faulty behavior",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 29,
      category: 2,
      text: "Describe a time you were manipulative",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 30,
      category: 2,
      text: "Explain about a time you were embarrassed",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 31,
      category: 2,
      text: "Disclose a time you dropped the ball",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 32,
      category: 2,
      text: "Reveal one of your hangups",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 33,
      category: 2,
      text: "Reveal a significant weakness",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 34,
      category: 2,
      text: "Describe one of your deep fears",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 35,
      category: 2,
      text: "Discuss a time you were shallow",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 36,
      category: 2,
      text: "Talk about a time you were wrong",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 37,
      category: 2,
      text: "Express a sincere regret",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 38,
      category: 2,
      text: "Admit a personal secret",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 39,
      category: 2,
      text: "Expose a sin you have committed",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 40,
      category: 2,
      text: "Illustrate an example of your vengeance",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 41,
      category: 2,
      text: "Articulate about a time you lied",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 42,
      category: 2,
      text: "Impart a significant sorrow",
image: "./images/card_back2.png",
frontImage: "./images/card_front2.png",
    },
    {
      id: 43,
      category: 3,
      text: "3 Needs",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 44,
      category: 3,
      text: "3 Favorite Foods",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 45,
      category: 3,
      text: "3 Questions",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 46,
      category: 3,
      text: "3 Favorite Books",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 47,
      category: 3,
      text: "3 Virtues",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 48,
      category: 3,
      text: "3 Goals for the Future",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 49,
      category: 3,
      text: "3 Vices",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 50,
      category: 3,
      text: "3 Boundaries",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 51,
      category: 3,
      text: "3 Worst Decisions",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 52,
      category: 3,
      text: "3 Influences",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 53,
      category: 3,
      text: "3 Areas of Interest",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 54,
      category: 3,
      text: "3 Deal Breakers",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 55,
      category: 3,
      text: "3 Travel Destinations",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 56,
      category: 3,
      text: "3 Spiritual Practices",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 57,
      category: 3,
      text: "3 Satisfied Desires",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 58,
      category: 3,
      text: "3 Expectations",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 59,
      category: 3,
      text: "3 Unsatisfied Desires",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 60,
      category: 3,
      text: "3 Disappointments",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 61,
      category: 3,
      text: "3 Most Important Values",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 62,
      category: 3,
      text: "3 Challenges",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 63,
      category: 3,
      text: "3 Triggers",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 64,
      category: 3,
      text: "3 Ambitions",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 65,
      category: 3,
      text: "3 Core Beliefs",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 66,
      category: 3,
      text: "3 Concerns",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 67,
      category: 3,
      text: "3 Asprirations",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 68,
      category: 3,
      text: "3 Favorite Movies",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 69,
      category: 3,
      text: "3 Bad Habits",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 70,
      category: 3,
      text: "3 Ways to Show Love",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 71,
      category: 3,
      text: "3 Fears",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 72,
      category: 3,
      text: "3 Most Valued Possessions",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 73,
      category: 3,
      text: "3 Past Wounds",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 74,
      category: 3,
      text: "3 Dirtiest Deeds",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 75,
      category: 3,
      text: "3 Favorite Activities",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 76,
      category: 3,
      text: "3 Worst Traits",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 77,
      category: 3,
      text: "3 Biggest Turn Ons",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 78,
      category: 3,
      text: "3 Best Qualities",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 79,
      category: 3,
      text: "3 Pet Peeves",
image: "./images/card_back3.png",
frontImage: "./images/card_front3.png",
    },
    {
      id: 80,
      category: 4,
      text: "- joke -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 81,
      category: 4,
      text: "- funny face -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 82,
      category: 4,
      text: "- silly dance -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 83,
      category: 4,
      text: "- random factoid -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 84,
      category: 4,
      text: "- quote -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 85,
      category: 4,
      text: "- poem -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 86,
      category: 4,
      text: "- anecdote -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 87,
      category: 4,
      text: "- fantasy -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 88,
      category: 4,
      text: "- riddle -",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 89,
      category: 4,
      text: "@ selfie @",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 90,
      category: 4,
      text: "Show and Tell",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 91,
      category: 4,
      text: "Talent Show!!",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 92,
      category: 4,
      text: "1...2...3...4...declare a thumb war",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 93,
      category: 4,
      text: "rock, paper, scissors",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
    {
      id: 94,
      category: 4,
      text: "XOXO hugs XOXO",
image: "./images/card_back4.png",
frontImage: "./images/card_front4.png",
    },
  ];
  
  export const categoryFiveCards = [
    {
      id: 95,
      category: 5,
      text: "Free Pass",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "freePass",
      description: "When used, this card allows you to automatically skip to the next player.",
    },
    {
      id: 96,
      category: 5,
      text: "Turn It Around",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "turnItAround",
      description: "When used, this card forces the other player to finish your turn.",
    },
    {
      id: 97,
      category: 5,
      text: "Do Over",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "doOver",
      description: "When used, this card allows you to select a different card from the same category.",
    },
    {
      id: 98,
      category: 5,
      text: "RESET",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "reset",
      description: "When used, this card initiates a 5 minute break for all players.",
    },
    {
      id: 99,
      category: 5,
      text: "Ask Me Anything",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "askMeAnything",
      description: "When used this card lets the other person ask you anything and you will receive 10 tokens for your answer.",
    },
    {
      id: 100,
      category: 5,
      text: "Go On...",
image: "./images/card_back5.png",
frontImage: "./images/card_front5.png",
      effect: "goOn",
      description: "When used, this card asks the other player to expand on their answer, but they will receive double the points.",
    },
  ];
