import React, { useState, useEffect } from "react";
import coinFavor from "../images/coin_favor.png";
import coinChallenge from "../images/coin_challenge.png";
import "../coin.css";

const Coin = ({ result }) => {
  const [isFlipping, setIsFlipping] = useState(true);
  const [side, setSide] = useState("");

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        setIsFlipping(false);
        setSide(result === "Favor" ? coinFavor : coinChallenge);
      }, 2000); // Match this duration with your CSS animation time
    }
  }, [result]);

  return (
    <div className="coin-container">
      <div className={`coin ${isFlipping ? "flipping" : ""}`}>
        {isFlipping ? (
          <>
            <img src={coinFavor} alt="Coin favor" className="coin-side coin-favor" />
            <img src={coinChallenge} alt="Coin challenge" className="coin-side coin-challenge" />
          </>
        ) : (
          <img src={side} alt={`Coin showing ${result}`} className="coin-image" />
        )}
      </div>
    </div>
  );
};

export default Coin;